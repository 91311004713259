import { jsx as t, jsxs as v, Fragment as J } from "react/jsx-runtime";
import { AlertGroup as Zt, Alert as en, AlertVariant as Me, AlertActionCloseButton as tn, Page as nn, Modal as vt, ModalVariant as rn, Button as L, TextContent as an, Text as Le, TextVariants as ln, Spinner as Ct, FormHelperText as He, HelperText as We, HelperTextItem as je, Popover as on, Icon as Ne, FormGroup as kt, NumberInput as sn, ValidatedOptions as Y, InputGroup as Ce, InputGroupItem as Be, TextInput as ke, Select as Ie, MenuToggle as de, MenuToggleStatus as Ge, SelectList as we, SelectOption as ze, TextInputGroup as It, TextInputGroupMain as wt, ChipGroup as Je, Chip as Ye, TextInputGroupUtilities as xt, Switch as cn, TextArea as Tt, Title as dn, Card as un, CardHeader as hn, CardTitle as pn, CardBody as mn, Grid as fn, GridItem as it, PageSection as gn, JumpLinks as yn, JumpLinksItem as bn, ButtonVariant as be, Checkbox as vn, Radio as Cn, MenuFooter as kn, Dropdown as In, DropdownList as wn, DropdownItem as st, Masthead as xn, MastheadToggle as Tn, PageToggleButton as Sn, MastheadBrand as An, MastheadContent as En, Toolbar as ye, ToolbarContent as Ve, ToolbarItem as K, Avatar as Rn, EmptyState as Dn, EmptyStateIcon as ct, EmptyStateHeader as On, EmptyStateBody as Fn, EmptyStateFooter as Pn, EmptyStateActions as Mn, SearchInput as Ln, Divider as Nn, Pagination as Bn, Bullseye as Vn, Badge as _n } from "@patternfly/react-core";
import { createContext as St, useContext as At, useRef as Q, useEffect as le, useCallback as ce, useState as x, useMemo as _, forwardRef as qn, useId as Et, Fragment as Rt, Children as Dt, Component as $n, isValidElement as Kn } from "react";
import { useTranslation as B } from "react-i18next";
import { NetworkError as Ot } from "@keycloak/keycloak-admin-client";
import Un from "keycloak-js";
import { ExclamationCircleIcon as Hn, HelpIcon as dt, EyeIcon as Wn, EyeSlashIcon as jn, TimesIcon as Ft, CubeIcon as Gn, PaypalIcon as zn, InstagramIcon as Jn, BitbucketIcon as Yn, MicrosoftIcon as Qn, TwitterIcon as Xn, StackOverflowIcon as Zn, OpenshiftIcon as er, LinkedinIcon as tr, GoogleIcon as nr, GitlabIcon as rr, FacebookSquareIcon as ar, GithubIcon as lr, MinusCircleIcon as or, PlusCircleIcon as Pt, EllipsisVIcon as ir, BarsIcon as sr, SearchIcon as cr, SyncAltIcon as dr } from "@patternfly/react-icons";
import { useFormContext as xe, Controller as oe, useController as Qe, FormProvider as ur, useWatch as hr } from "react-hook-form";
import { get as U, cloneDeep as pr, differenceBy as mr } from "lodash-es";
import me from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as fr } from "@patternfly/react-styles";
import { Table as gr, TableVariant as yr, Thead as br, Tr as fe, Th as Oe, Tbody as ut, Td as re, ActionsColumn as vr, ExpandableRowContent as Cr, TableText as kr } from "@patternfly/react-table";
import './main.css';function Xe(e, n) {
  const r = St(n);
  return r.displayName = e, r;
}
const Ir = ["error", "errorMessage"], Fe = "error_description";
function wr(e) {
  if (typeof e == "string")
    return e;
  if (e instanceof Ot)
    return Sr(e.responseData);
  if (e instanceof Error)
    return e.message;
  throw new Error("Unable to determine error message.");
}
function xr(e) {
  if (!(e instanceof Ot))
    return;
  const n = e.responseData;
  return Tr(n);
}
function Tr(e) {
  if (typeof e == "object" && e !== null && Fe in e && typeof e[Fe] == "string")
    return e[Fe];
}
function Sr(e) {
  if (!(typeof e != "object" || e === null))
    for (const n of Ir) {
      const r = e[n];
      if (typeof r == "string")
        return r;
    }
}
const Ar = () => Math.floor(Math.random() * 1e3);
function Er(e) {
  return e != null;
}
function Ze(e) {
  const n = At(e);
  if (Er(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function Rr() {
  const e = Q(!1), n = Q(/* @__PURE__ */ new Set());
  le(() => (e.current = !1, () => {
    e.current = !0, r();
  }), []);
  function r() {
    n.current.forEach((a) => clearTimeout(a)), n.current.clear();
  }
  return ce((a, l) => {
    if (e.current)
      throw new Error("Can't schedule a timeout on an unmounted component.");
    const o = Number(setTimeout(i, l));
    n.current.add(o);
    function i() {
      n.current.delete(o), a();
    }
    return function() {
      clearTimeout(o), n.current.delete(o);
    };
  }, []);
}
function Dr({ alerts: e, onCloseAlert: n }) {
  return /* @__PURE__ */ t(
    Zt,
    {
      "data-testid": "global-alerts",
      isToast: !0,
      style: { whiteSpace: "pre-wrap" },
      children: e.map(({ id: r, variant: a, message: l, description: o }, i) => /* @__PURE__ */ t(
        en,
        {
          "data-testid": i === 0 ? "last-alert" : void 0,
          isLiveRegion: !0,
          variant: Me[a],
          component: "p",
          variantLabel: "",
          title: l,
          actionClose: /* @__PURE__ */ t(
            tn,
            {
              title: l,
              onClose: () => n(r)
            }
          ),
          children: o && /* @__PURE__ */ t("p", { children: o })
        },
        r
      ))
    }
  );
}
const Or = 8e3, Mt = Xe(
  "AlertContext",
  void 0
), Va = () => Ze(Mt), Fr = ({ children: e }) => {
  const { t: n } = B(), r = Rr(), [a, l] = x([]), o = (c) => l((h) => h.filter((u) => u.id !== c)), i = ce(
    (c, h = Me.success, u) => {
      const f = {
        id: Ar(),
        message: c,
        variant: h,
        description: u
      };
      l((m) => [f, ...m]), r(() => o(f.id), Or);
    },
    [r]
  ), s = ce(
    (c, h) => {
      const u = n(c, { error: wr(h) }), f = xr(h);
      i(u, Me.danger, f);
    },
    [i, n]
  ), d = _(() => ({ addAlert: i, addError: s }), [i, s]);
  return /* @__PURE__ */ v(Mt.Provider, { value: d, children: [
    /* @__PURE__ */ t(Dr, { alerts: a, onCloseAlert: o }),
    e
  ] });
}, Pr = (e) => {
  const { t: n } = B(), r = e.error, a = Mr(r);
  function l() {
    location.href = location.origin + location.pathname;
  }
  return /* @__PURE__ */ t(nn, { children: /* @__PURE__ */ t(
    vt,
    {
      variant: rn.small,
      title: n("somethingWentWrong"),
      titleIconVariant: "danger",
      showClose: !1,
      isOpen: !0,
      actions: [
        /* @__PURE__ */ t(L, { variant: "primary", onClick: l, children: n("tryAgain") }, "tryAgain")
      ],
      children: /* @__PURE__ */ v(an, { children: [
        /* @__PURE__ */ t(Le, { children: n("somethingWentWrongDescription") }),
        a && /* @__PURE__ */ t(Le, { component: ln.small, children: a })
      ] })
    }
  ) });
};
function Mr(e) {
  return typeof e == "string" ? e : e instanceof Error ? e.message : null;
}
function Lr(e, n, r) {
  const [a, l] = x(
    () => e.getItem(n) ?? r
  ), o = ce((i) => {
    l(i), e.setItem(n, i);
  }, []);
  return le(() => {
    l(e.getItem(n) ?? r), window.addEventListener("storage", i);
    function i(s) {
      s.storageArea === e && (s.key === null || s.key === n) && l(s.newValue ?? r);
    }
    return () => window.removeEventListener("storage", i);
  }, [e, n]), [a, o];
}
function Lt(e, n, r) {
  const a = _(
    () => JSON.stringify(r),
    [r]
  ), [l, o] = Lr(
    e,
    n,
    a
  ), i = _(() => JSON.parse(l), [l]), s = ce(
    (d) => o(JSON.stringify(d)),
    []
  );
  return [i, s];
}
const Nt = Xe(
  "HelpContext",
  void 0
), Nr = () => Ze(Nt), Br = ({ children: e }) => {
  const [n, r] = Lt(localStorage, "helpEnabled", !0);
  function a() {
    r(!n);
  }
  return /* @__PURE__ */ t(Nt.Provider, { value: { enabled: n, toggleHelp: a }, children: e });
}, Vr = () => St(void 0);
let _e;
const _a = () => {
  const e = At(_e);
  if (!e)
    throw Error(
      "no environment provider in the hierarchy make sure to add the provider"
    );
  return e;
}, qa = ({
  environment: e,
  children: n
}) => {
  _e = Vr();
  const r = Q(!1), [a, l] = x(!1), [o, i] = x(), s = _(() => {
    const d = new Un({
      url: e.serverBaseUrl,
      realm: e.realm,
      clientId: e.clientId
    });
    return d.onAuthLogout = () => d.login(), d;
  }, [e]);
  return le(() => {
    if (r.current)
      return;
    s.init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      responseMode: "query"
    }).then(() => l(!0)).catch((c) => i(c)), r.current = !0;
  }, [s]), o ? /* @__PURE__ */ t(Pr, { error: o }) : a ? /* @__PURE__ */ t(_e.Provider, { value: { environment: e, keycloak: s }, children: /* @__PURE__ */ t(Fr, { children: /* @__PURE__ */ t(Br, { children: n }) }) }) : /* @__PURE__ */ t(Ct, {});
};
function $a() {
  const n = document.getElementById("environment")?.textContent;
  if (typeof n != "string")
    throw new Error("Environment variables not found in the document.");
  try {
    return JSON.parse(n);
  } catch {
    throw new Error("Unable to parse environment variables as JSON.");
  }
}
const Ka = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: r,
  buttonTitle: a,
  isDisabled: l,
  buttonVariant: o,
  buttonTestRole: i,
  onContinue: s,
  component: d = L,
  children: c,
  ...h
}) => {
  const [u, f] = x(!1);
  return /* @__PURE__ */ v(J, { children: [
    /* @__PURE__ */ t(
      d,
      {
        variant: o,
        onClick: () => f(!0),
        isDisabled: l,
        "data-testrole": i,
        children: a
      }
    ),
    /* @__PURE__ */ t(
      vt,
      {
        variant: "small",
        ...h,
        title: e,
        isOpen: u,
        onClose: () => f(!1),
        actions: [
          /* @__PURE__ */ t(
            L,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                f(!1), s();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ t(
            L,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => f(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: c
      }
    )
  ] });
}, Bt = ({ message: e, ...n }) => /* @__PURE__ */ t(He, { ...n, children: /* @__PURE__ */ t(We, { children: /* @__PURE__ */ t(je, { icon: /* @__PURE__ */ t(Hn, {}), variant: "error", children: e }) }) }), Vt = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: r = !0,
  unWrap: a = !1
}) => {
  const { enabled: l } = Nr();
  return l ? /* @__PURE__ */ t(on, { bodyContent: e, children: /* @__PURE__ */ v(J, { children: [
    !a && /* @__PURE__ */ t(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (o) => o.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ t(Ne, { isInline: r, children: /* @__PURE__ */ t(dt, {}) })
      }
    ),
    a && /* @__PURE__ */ t(Ne, { isInline: r, children: /* @__PURE__ */ t(dt, {}) })
  ] }) }) : null;
};
function et(e) {
  if (!(typeof e > "u" || e instanceof RegExp))
    return typeof e == "object" ? e.value : e;
}
const ne = ({
  name: e,
  label: n,
  labelIcon: r,
  error: a,
  children: l,
  ...o
}) => /* @__PURE__ */ v(
  kt,
  {
    label: n || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ t(Vt, { helpText: r, fieldLabelId: e }) : void 0,
    ...o,
    children: [
      l,
      a && /* @__PURE__ */ t(Bt, { "data-testid": `${e}-helper`, message: a.message })
    ]
  }
), Ua = ({
  name: e,
  label: n,
  controller: r,
  labelIcon: a,
  ...l
}) => {
  const {
    control: o,
    formState: { errors: i }
  } = xe();
  return /* @__PURE__ */ t(
    ne,
    {
      name: e,
      label: n,
      isRequired: r.rules?.required === !0,
      error: i[e],
      labelIcon: a,
      children: /* @__PURE__ */ t(
        oe,
        {
          ...r,
          name: e,
          control: o,
          render: ({ field: s }) => {
            const d = !!r.rules?.required, c = et(r.rules?.min), h = s.value ?? r.defaultValue, u = (f) => s.onChange(
              c !== void 0 ? Math.max(f, Number(c)) : f
            );
            return /* @__PURE__ */ t(
              sn,
              {
                ...l,
                id: e,
                value: h,
                validated: i[e] ? Y.error : Y.default,
                required: d,
                min: Number(c),
                max: Number(r.rules?.max),
                onPlus: () => u(h + 1),
                onMinus: () => u(h - 1),
                onChange: (f) => {
                  const m = Number(f.currentTarget.value);
                  u(isNaN(m) ? r.defaultValue : m);
                }
              }
            );
          }
        }
      )
    }
  );
}, _r = ({
  hasReveal: e = !0,
  innerRef: n,
  ...r
}) => {
  const { t: a } = B(), [l, o] = x(!0);
  return /* @__PURE__ */ v(Ce, { children: [
    /* @__PURE__ */ t(Be, { isFill: !0, children: /* @__PURE__ */ t(
      ke,
      {
        ...r,
        type: l ? "password" : "text",
        ref: n
      }
    ) }),
    e && /* @__PURE__ */ t(
      L,
      {
        variant: "control",
        "aria-label": a("showPassword"),
        onClick: () => o(!l),
        children: l ? /* @__PURE__ */ t(Wn, {}) : /* @__PURE__ */ t(jn, {})
      }
    )
  ] });
}, _t = qn(
  (e, n) => /* @__PURE__ */ t(_r, { ...e, innerRef: n })
);
_t.displayName = "PasswordInput";
const Ha = (e) => {
  const { labelIcon: n, ...r } = e, a = !!e.rules?.required, l = e.defaultValue ?? "", { field: o, fieldState: i } = Qe({
    ...e,
    defaultValue: l
  });
  return /* @__PURE__ */ v(
    ne,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: a,
      error: i.error,
      children: [
        /* @__PURE__ */ t(
          _t,
          {
            isRequired: a,
            id: e.name,
            "data-testid": e.name,
            validated: i.error ? Y.error : Y.default,
            isDisabled: e.isDisabled,
            ...r,
            ...o
          }
        ),
        e.helperText && /* @__PURE__ */ t(He, { children: /* @__PURE__ */ t(We, { children: /* @__PURE__ */ t(je, { children: e.helperText }) }) })
      ]
    }
  );
}, qr = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: o,
  ...i
}) => {
  const {
    control: s,
    formState: { errors: d }
  } = xe(), [c, h] = x(!1), u = et(l.rules?.required) === !0;
  return /* @__PURE__ */ t(
    ne,
    {
      name: n,
      label: r,
      isRequired: u,
      error: U(d, n),
      labelIcon: o,
      children: /* @__PURE__ */ t(
        oe,
        {
          ...l,
          name: n,
          control: s,
          render: ({ field: { onChange: f, value: m } }) => /* @__PURE__ */ t(
            Ie,
            {
              ...i,
              onClick: () => h(!c),
              onOpenChange: () => h(!1),
              selected: se(a) ? a.filter(
                (b) => Array.isArray(m) ? m.includes(b.key) : m === b.key
              ).map((b) => b.value) : m,
              toggle: (b) => /* @__PURE__ */ t(
                de,
                {
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  ref: b,
                  onClick: () => h(!c),
                  isExpanded: c,
                  isFullWidth: !0,
                  status: U(d, n) ? Ge.danger : void 0,
                  "aria-label": "toggle",
                  children: se(a) ? a.find(
                    (T) => T.key === (Array.isArray(m) ? m[0] : m)
                  )?.value : m
                }
              ),
              onSelect: (b, T) => {
                const g = T?.toString();
                f(Array.isArray(m) ? [g] : g), h(!1);
              },
              isOpen: c,
              children: /* @__PURE__ */ t(we, { children: a.map((b) => /* @__PURE__ */ t(ze, { value: ee(b), children: tt(b) ? b : b.value }, ee(b))) })
            }
          )
        }
      )
    }
  );
}, ge = (e) => tt(e) ? e : e.value, $r = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: o,
  placeholderText: i,
  onFilter: s,
  variant: d,
  ...c
}) => {
  const {
    control: h,
    formState: { errors: u }
  } = xe(), [f, m] = x(!1), [b, T] = x(""), [g, p] = x(0), C = Q(), w = et(l.rules?.required) === !0, k = a.filter(
    (y) => ge(y).toLowerCase().startsWith(b.toLowerCase())
  ), A = _(
    () => k.map((y, S) => /* @__PURE__ */ t(
      ze,
      {
        value: ee(y),
        isFocused: g === S,
        children: ge(y)
      },
      ee(y)
    )),
    [g, k]
  ), V = (y, S) => {
    const D = k[g];
    switch (m(!0), y.key) {
      case "Enter": {
        y.preventDefault(), d !== ae.typeaheadMulti ? T(ge(D)) : T(""), S.onChange(
          Array.isArray(S.value) ? [...S.value, ee(D)] : ee(D)
        ), m(!1), p(0);
        break;
      }
      case "Tab":
      case "Escape": {
        m(!1), S.onChange(void 0);
        break;
      }
      case "Backspace": {
        d === ae.typeahead && S.onChange("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        y.preventDefault();
        let F = 0;
        y.key === "ArrowUp" && (g === 0 ? F = a.length - 1 : F = g - 1), y.key === "ArrowDown" && (g === a.length - 1 ? F = 0 : F = g + 1), p(F);
        break;
      }
    }
  };
  return /* @__PURE__ */ t(
    ne,
    {
      name: n,
      label: r,
      isRequired: w,
      error: U(u, n),
      labelIcon: o,
      children: /* @__PURE__ */ t(
        oe,
        {
          ...l,
          name: n,
          control: h,
          render: ({ field: y }) => /* @__PURE__ */ t(
            Ie,
            {
              ...c,
              onClick: () => m(!f),
              onOpenChange: () => m(!1),
              selected: se(a) ? a.filter(
                (S) => Array.isArray(y.value) ? y.value.includes(S.key) : y.value === S.key
              ).map((S) => S.value) : y.value,
              toggle: (S) => /* @__PURE__ */ t(
                de,
                {
                  ref: S,
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  variant: "typeahead",
                  onClick: () => m(!f),
                  isExpanded: f,
                  isFullWidth: !0,
                  status: U(u, n) ? Ge.danger : void 0,
                  children: /* @__PURE__ */ v(It, { isPlain: !0, children: [
                    /* @__PURE__ */ t(
                      wt,
                      {
                        placeholder: i,
                        value: d === ae.typeahead && y.value ? se(a) ? a.find(
                          (D) => D.key === (Array.isArray(y.value) ? y.value[0] : y.value)
                        )?.value : y.value : b,
                        onClick: () => m(!f),
                        onChange: (D, F) => {
                          T(F), s?.(F);
                        },
                        onKeyDown: (D) => V(D, y),
                        autoComplete: "off",
                        innerRef: C,
                        role: "combobox",
                        isExpanded: f,
                        "aria-controls": "select-typeahead-listbox",
                        children: d === ae.typeaheadMulti && Array.isArray(y.value) && /* @__PURE__ */ t(Je, { "aria-label": "Current selections", children: y.value.map(
                          (D, F) => /* @__PURE__ */ t(
                            Ye,
                            {
                              onClick: (E) => {
                                E.stopPropagation(), y.onChange(
                                  y.value.filter(
                                    (P) => P !== ee(D)
                                  )
                                );
                              },
                              children: se(a) ? a.find((E) => D === E.key)?.value : ge(D)
                            },
                            F
                          )
                        ) })
                      }
                    ),
                    /* @__PURE__ */ t(xt, { children: (!!b || y.value) && /* @__PURE__ */ t(
                      L,
                      {
                        variant: "plain",
                        onClick: () => {
                          T(""), y.onChange(""), C?.current?.focus();
                        },
                        "aria-label": "Clear input value",
                        children: /* @__PURE__ */ t(Ft, { "aria-hidden": !0 })
                      }
                    ) })
                  ] })
                }
              ),
              onSelect: (S, D) => {
                S?.stopPropagation();
                const F = D?.toString();
                d === ae.typeaheadMulti && Array.isArray(y.value) ? y.value.includes(F) ? y.onChange(
                  y.value.filter((E) => E !== F)
                ) : y.onChange([...y.value, F]) : (y.onChange(Array.isArray(y.value) ? [F] : F), m(!1));
              },
              isOpen: f,
              children: /* @__PURE__ */ t(we, { children: A })
            }
          )
        }
      )
    }
  );
};
var ae = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(ae || {});
const se = (e) => typeof e[0] != "string", tt = (e) => typeof e == "string", ee = (e) => tt(e) ? e : e.key, Kr = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(qr, { ...n }) : /* @__PURE__ */ t($r, { ...n, variant: e }), Wa = ({
  labelOn: e,
  stringify: n,
  defaultValue: r,
  labelIcon: a,
  ...l
}) => {
  const i = r ?? (n ? "false" : !1), { control: s } = xe();
  return /* @__PURE__ */ t(
    ne,
    {
      hasNoPaddingTop: !0,
      name: l.name,
      isRequired: l.rules?.required === !0,
      label: l.label,
      labelIcon: a,
      children: /* @__PURE__ */ t(
        oe,
        {
          control: s,
          name: l.name,
          defaultValue: i,
          render: ({ field: { onChange: d, value: c } }) => /* @__PURE__ */ t(
            cn,
            {
              ...l,
              id: l.name,
              "data-testid": l.name,
              label: e,
              isChecked: n ? c === "true" : c,
              onChange: (h, u) => {
                const f = n ? u.toString() : u;
                l.onChange?.(h, u), d(f);
              }
            }
          )
        }
      )
    }
  );
}, ja = (e) => {
  const n = !!e.rules?.required, r = e.defaultValue ?? "", { field: a, fieldState: l } = Qe({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ t(
    ne,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: l.error,
      children: /* @__PURE__ */ t(
        Tt,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: l.error ? Y.error : Y.default,
          isDisabled: e.isDisabled,
          ...a
        }
      )
    }
  );
}, Ga = (e) => {
  const { labelIcon: n, helperText: r, ...a } = e, l = !!e.rules?.required, o = e.defaultValue ?? "", { field: i, fieldState: s } = Qe({
    ...e,
    defaultValue: o
  });
  return /* @__PURE__ */ v(
    ne,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: l,
      error: s.error,
      children: [
        /* @__PURE__ */ t(
          ke,
          {
            isRequired: l,
            id: e.name,
            "data-testid": e["data-testid"] || e.name,
            validated: s.error ? Y.error : Y.default,
            isDisabled: e.isDisabled,
            ...a,
            ...i
          }
        ),
        r && /* @__PURE__ */ t(He, { children: /* @__PURE__ */ t(We, { children: /* @__PURE__ */ t(je, { children: r }) }) })
      ]
    }
  );
}, Ur = Tt, za = ({ icon: e }) => {
  const n = Hr(e);
  return /* @__PURE__ */ t(Ne, { size: "lg", children: /* @__PURE__ */ t(n, { alt: e }) });
};
function Hr(e) {
  switch (e) {
    case "github":
      return lr;
    case "facebook":
      return ar;
    case "gitlab":
      return rr;
    case "google":
      return nr;
    case "linkedin":
    case "linkedin-openid-connect":
      return tr;
    case "openshift-v3":
    case "openshift-v4":
      return er;
    case "stackoverflow":
      return Zn;
    case "twitter":
      return Xn;
    case "microsoft":
      return Qn;
    case "bitbucket":
      return Yn;
    case "instagram":
      return Jn;
    case "paypal":
      return zn;
    default:
      return Gn;
  }
}
const Wr = "_title_180i0_2", jr = {
  title: Wr
}, qt = ({
  id: e,
  title: n,
  headingLevel: r = "h1",
  size: a = "xl",
  ...l
}) => /* @__PURE__ */ t(
  dn,
  {
    headingLevel: r,
    size: a,
    className: jr.title,
    id: e,
    tabIndex: 0,
    ...l,
    children: n
  }
), Gr = ({
  title: e,
  children: n,
  scrollId: r,
  className: a
}) => {
  const l = Et();
  return /* @__PURE__ */ v(un, { id: l, className: a, isFlat: !0, children: [
    /* @__PURE__ */ t(hn, { className: "kc-form-panel__header", children: /* @__PURE__ */ t(pn, { tabIndex: 0, children: /* @__PURE__ */ t(qt, { id: r, title: e }) }) }),
    /* @__PURE__ */ t(mn, { className: "kc-form-panel__body", children: n })
  ] });
}, zr = (e) => {
  const { title: n, children: r, scrollId: a, ...l } = e;
  return /* @__PURE__ */ t("section", { ...l, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ v(J, { children: [
    /* @__PURE__ */ t(qt, { id: a, title: n }),
    r
  ] }) });
}, Jr = "_panel_1cdve_1", Yr = "_sticky_1cdve_5", ht = {
  panel: Jr,
  sticky: Yr
}, Qr = "kc-main-content-page-container", pt = (e) => e.replace(/\s+/g, "-"), Xr = ({
  label: e,
  sections: n,
  borders: r = !1,
  ...a
}) => {
  const l = _(
    () => n.filter(({ isHidden: o }) => !o),
    [n]
  );
  return /* @__PURE__ */ v(fn, { hasGutter: !0, ...a, children: [
    /* @__PURE__ */ t(it, { md: 8, sm: 12, children: l.map(({ title: o, panel: i }) => {
      const s = pt(o.toLowerCase());
      return /* @__PURE__ */ t(Rt, { children: r ? /* @__PURE__ */ t(
        Gr,
        {
          scrollId: s,
          title: o,
          className: ht.panel,
          children: i
        }
      ) : /* @__PURE__ */ t(zr, { scrollId: s, title: o, children: i }) }, o);
    }) }),
    /* @__PURE__ */ t(it, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ t(gn, { className: ht.sticky, children: /* @__PURE__ */ t(
      yn,
      {
        isVertical: !0,
        scrollableSelector: `#${Qr}`,
        label: e,
        offset: 100,
        children: l.map(({ title: o }) => {
          const i = pt(o.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ t(
              bn,
              {
                href: `#${i}`,
                "data-testid": `jump-link-${i}`,
                children: o
              },
              o
            )
          );
        })
      }
    ) }) })
  ] });
}, Zr = (e, n, r) => (e.isValid || r) && (e.isDirty || n) && !e.isLoading && !e.isValidating && !e.isSubmitting, Ja = ({
  formState: e,
  isDisabled: n = !1,
  allowInvalid: r = !1,
  allowNonDirty: a = !1,
  children: l,
  ...o
}) => /* @__PURE__ */ t(
  L,
  {
    variant: "primary",
    isDisabled: e && !Zr(e, a, r) || n,
    ...o,
    type: "submit",
    children: l
  }
), ea = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, ta = ({
  t: e,
  form: n,
  supportedLocales: r,
  currentLocale: a
}) => {
  const l = _(
    () => r.map((o) => ({
      key: o,
      value: e(`locale_${o}`, ea(o) ?? o)
    })).sort((o, i) => o.value.localeCompare(i.value, a)),
    [r, a, e]
  );
  return l.length ? /* @__PURE__ */ t(ur, { ...n, children: /* @__PURE__ */ t(
    Kr,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: l,
      variant: l.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, qe = (e) => e?.includes("${"), $e = (e) => e.substring(2, e.length - 1), te = (e, n, r, a) => {
  const l = n || r, o = qe(l) ? $e(l) : l, i = a ? `${a}.${o}` : o;
  return e(i || "");
}, Ke = (e, n) => te(e, n.displayName, n.name), na = ["username", "firstName", "lastName", "email"], $t = (e) => e && na.includes(e), X = (e) => `${$t(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, Ya = (e) => e.replaceAll(".", "🍺"), Qa = (e) => e.replaceAll("🍺", ".");
function Xa(e, n, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((a) => {
    const l = Object.assign(
      {},
      a.params?.map((o) => qe(o.toString()) ? r($e(o)) : o)
    );
    n(X(a.field), {
      message: r(
        qe(a.errorMessage) ? $e(a.errorMessage) : a.errorMessage,
        {
          ...l,
          defaultValue: a.errorMessage || a.field
        }
      ),
      type: "server"
    });
  });
}
function Te({
  required: e,
  validators: n
}) {
  return e || ra(n);
}
function ra(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function Za(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return mt(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(mt);
}
function mt(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const ue = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  children: l
}) => {
  const o = te(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: i }
  } = n, s = a?.(r), d = U(i, X(r.name));
  return /* @__PURE__ */ v(
    kt,
    {
      label: Ke(e, r) || "",
      fieldId: r.name,
      isRequired: Te(r),
      labelIcon: o ? /* @__PURE__ */ t(Vt, { helpText: o, fieldLabelId: r.name }) : void 0,
      children: [
        s ? /* @__PURE__ */ v(Ce, { children: [
          l,
          s
        ] }) : l,
        d && /* @__PURE__ */ t(
          Bt,
          {
            "data-testid": `${r.name}-helper`,
            message: d.message
          }
        )
      ]
    },
    r.name
  );
}, aa = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  ...l
}) => /* @__PURE__ */ t(ue, { t: e, form: n, attribute: r, renderer: a, children: /* @__PURE__ */ t(
  la,
  {
    t: e,
    form: n,
    "aria-label": Ke(e, r),
    name: X(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: Ke(e, r)
    }),
    ...l
  }
) }), la = ({
  t: e,
  name: n,
  inputType: r,
  form: a,
  addButtonLabel: l,
  isDisabled: o = !1,
  defaultValue: i,
  id: s,
  ...d
}) => {
  const { register: c, setValue: h, control: u } = a, f = hr({
    name: n,
    control: u,
    defaultValue: i || ""
  }), m = _(() => Array.isArray(f) && f.length !== 0 ? f : i || [""], [f]), b = (w) => {
    p([...m.slice(0, w), ...m.slice(w + 1)]);
  }, T = () => {
    p([...m, ""]);
  }, g = (w, k) => {
    p([...m.slice(0, w), k, ...m.slice(w + 1)]);
  }, p = (w) => {
    const k = w.flatMap((A) => A);
    h(n, k, {
      shouldDirty: !0
    });
  }, C = r.startsWith("html") ? r.substring(6) : "text";
  return le(() => {
    c(n);
  }, [c]), /* @__PURE__ */ t("div", { id: s, children: m.map((w, k) => /* @__PURE__ */ v(Rt, { children: [
    /* @__PURE__ */ v(Ce, { children: [
      /* @__PURE__ */ t(Be, { isFill: !0, children: /* @__PURE__ */ t(
        ke,
        {
          "data-testid": n + k,
          onChange: (A, V) => g(k, V),
          name: `${n}.${k}.value`,
          value: w,
          isDisabled: o,
          type: C,
          ...d
        }
      ) }),
      /* @__PURE__ */ t(Be, { children: /* @__PURE__ */ t(
        L,
        {
          "data-testid": "remove" + k,
          variant: be.link,
          onClick: () => b(k),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: m.length === 1 || o,
          children: /* @__PURE__ */ t(or, {})
        }
      ) })
    ] }),
    k === m.length - 1 && /* @__PURE__ */ v(
      L,
      {
        variant: be.link,
        onClick: T,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !w || o,
        children: [
          /* @__PURE__ */ t(Pt, {}),
          " ",
          e(l || "add")
        ]
      }
    )
  ] }, k)) });
}, ft = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = Te(a), o = r.startsWith("multiselect"), i = o ? vn : Cn, s = a.validators?.options?.options || [], d = a.annotations?.inputOptionLabels || {}, c = a.annotations?.inputOptionLabelsI18nPrefix;
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    oe,
    {
      name: X(a.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: h }) => /* @__PURE__ */ t(J, { children: s.map((u) => /* @__PURE__ */ t(
        i,
        {
          id: u,
          "data-testid": u,
          label: te(e.t, d[u], u, c),
          value: u,
          isChecked: h.value.includes(u),
          onChange: () => {
            o ? h.value.includes(u) ? h.onChange(
              h.value.filter((f) => f !== u)
            ) : h.onChange([...h.value, u]) : h.onChange([u]);
          },
          readOnly: a.readOnly,
          isRequired: l
        },
        u
      )) })
    }
  ) });
}, oa = ({
  toggleId: e,
  onToggle: n,
  onSelect: r,
  selections: a,
  isOpen: l,
  menuAppendTo: o,
  direction: i,
  width: s,
  maxHeight: d,
  toggleIcon: c,
  className: h,
  isDisabled: u,
  children: f,
  ...m
}) => {
  const [b, T] = x(!1), g = Q(), p = () => {
    T(!b), n(!b);
  }, C = () => o === "parent" && g.current?.parentElement || "inline", w = Dt.toArray(
    f
  );
  return /* @__PURE__ */ t(
    Ie,
    {
      ref: g,
      maxMenuHeight: ve(d),
      isScrollable: !0,
      popperProps: {
        appendTo: C(),
        direction: i,
        width: ve(s)
      },
      ...m,
      onClick: p,
      onOpenChange: (k) => T(k),
      selected: a,
      onSelect: (k, A) => {
        r?.(A || ""), p();
      },
      toggle: (k) => /* @__PURE__ */ t(
        de,
        {
          id: e,
          ref: k,
          className: h,
          onClick: p,
          isExpanded: l,
          "aria-label": m["aria-label"],
          icon: c,
          isDisabled: u,
          isFullWidth: !0,
          children: w.find((A) => A.props.value === a)?.props.children || a || m["aria-label"]
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(we, { children: f })
    }
  );
}, ia = ({
  toggleId: e,
  onSelect: n,
  onToggle: r,
  onFilter: a,
  variant: l,
  validated: o,
  placeholderText: i,
  maxHeight: s,
  width: d,
  toggleIcon: c,
  direction: h,
  selections: u,
  typeAheadAriaLabel: f,
  chipGroupComponent: m,
  chipGroupProps: b,
  footer: T,
  isDisabled: g,
  children: p,
  ...C
}) => {
  const [w, k] = x(""), [A, V] = x(0), y = Q(), S = Dt.toArray(
    p
  ), D = () => {
    r?.(!C.isOpen);
  }, F = (E) => {
    const P = S[A];
    switch (r?.(!0), E.key) {
      case "Enter": {
        E.preventDefault(), l !== z.typeaheadMulti ? k(P.props.value) : k(""), n?.(P.props.value), r?.(!1), V(0);
        break;
      }
      case "Escape": {
        r?.(!1);
        break;
      }
      case "Backspace": {
        l === z.typeahead && n?.("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        E.preventDefault();
        let N = 0;
        E.key === "ArrowUp" && (A === 0 ? N = S.length - 1 : N = A - 1), E.key === "ArrowDown" && (A === S.length - 1 ? N = 0 : N = A + 1), V(N);
        break;
      }
    }
  };
  return /* @__PURE__ */ v(
    Ie,
    {
      ...C,
      onClick: D,
      onOpenChange: (E) => r?.(E),
      onSelect: (E, P) => n?.(P || ""),
      maxMenuHeight: ve(s),
      popperProps: { direction: h, width: ve(d) },
      toggle: (E) => /* @__PURE__ */ t(
        de,
        {
          ref: E,
          id: e,
          variant: "typeahead",
          onClick: () => r?.(!0),
          icon: c,
          isDisabled: g,
          isExpanded: C.isOpen,
          isFullWidth: !0,
          status: o === "error" ? Ge.danger : void 0,
          children: /* @__PURE__ */ v(It, { isPlain: !0, children: [
            /* @__PURE__ */ t(
              wt,
              {
                placeholder: i,
                value: l === z.typeahead && u ? u : w,
                onClick: D,
                onChange: (P, N) => {
                  k(N), a?.(N);
                },
                onKeyDown: (P) => F(P),
                autoComplete: "off",
                innerRef: y,
                role: "combobox",
                isExpanded: C.isOpen,
                "aria-controls": "select-typeahead-listbox",
                "aria-label": f,
                children: l === z.typeaheadMulti && Array.isArray(u) && (m || /* @__PURE__ */ t(Je, { ...b, children: u.map((P, N) => /* @__PURE__ */ t(
                  Ye,
                  {
                    onClick: (Se) => {
                      Se.stopPropagation(), n?.(P);
                    },
                    children: P
                  },
                  N
                )) }))
              }
            ),
            /* @__PURE__ */ t(xt, { children: !!w && /* @__PURE__ */ t(
              L,
              {
                variant: "plain",
                onClick: () => {
                  n?.(""), k(""), a?.(""), y?.current?.focus();
                },
                "aria-label": "Clear input value",
                children: /* @__PURE__ */ t(Ft, { "aria-hidden": !0 })
              }
            ) })
          ] })
        }
      ),
      children: [
        /* @__PURE__ */ t(we, { children: p }),
        T && /* @__PURE__ */ t(kn, { children: T })
      ]
    }
  );
};
var z = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(z || {});
const ve = (e) => typeof e == "number" ? e + "px" : e, sa = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(oa, { ...n }) : /* @__PURE__ */ t(ia, { ...n, variant: e }), gt = (e) => {
  const { t: n, form: r, inputType: a, attribute: l } = e, [o, i] = x(!1), [s, d] = x(""), c = a === "multiselect", h = (g, p) => {
    c ? p.value.includes(g) ? p.onChange(p.value.filter((C) => C !== g)) : Array.isArray(p.value) ? p.onChange([...p.value, g]) : p.onChange([g]) : p.onChange(g === p.value ? "" : g);
  }, u = l.validators?.options?.options || [], f = l.annotations?.inputOptionLabels || {}, m = l.annotations?.inputOptionLabelsI18nPrefix, b = (g) => te(e.t, f[g], g, m), T = (g) => u.filter(
    (p) => b(p).toLowerCase().includes(s.toLowerCase())
  ).map((p) => /* @__PURE__ */ t(
    ze,
    {
      selected: g === p,
      value: p,
      children: b(p)
    },
    p
  ));
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    oe,
    {
      name: X(l.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: g }) => /* @__PURE__ */ t(
        sa,
        {
          toggleId: l.name,
          onToggle: (p) => i(p),
          onClear: () => h("", g),
          onSelect: (p) => {
            const C = p.toString();
            h(C, g), Array.isArray(g.value) || i(!1);
          },
          selections: c && Array.isArray(g.value) ? g.value : b(g.value),
          variant: c ? z.typeaheadMulti : u.length >= 10 ? z.typeahead : z.single,
          "aria-label": n("selectOne"),
          isOpen: o,
          isDisabled: l.readOnly,
          onFilter: (p) => (d(p), T(g.value)),
          children: T(g.value)
        }
      )
    }
  ) });
}, ca = (e) => {
  const { form: n, attribute: r } = e, a = Te(r);
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    Ur,
    {
      id: r.name,
      "data-testid": r.name,
      ...n.register(X(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: a
    }
  ) });
}, q = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = Te(a), o = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ t(ue, { ...e, children: /* @__PURE__ */ t(
    ke,
    {
      id: a.name,
      "data-testid": a.name,
      type: o,
      placeholder: te(
        e.t,
        a.annotations?.inputTypePlaceholder,
        a.name,
        a.annotations?.inputOptionLabelsI18nPrefix
      ),
      readOnly: a.readOnly,
      isRequired: l,
      ...n.register(X(a.name))
    }
  ) });
}, Ue = {
  text: q,
  textarea: ca,
  select: gt,
  "select-radiobuttons": ft,
  multiselect: gt,
  "multiselect-checkboxes": ft,
  "html5-email": q,
  "html5-tel": q,
  "html5-url": q,
  "html5-number": q,
  "html5-range": q,
  "html5-datetime-local": q,
  "html5-date": q,
  "html5-month": q,
  "html5-time": q,
  "multi-input": aa
}, el = ({
  t: e,
  form: n,
  userProfileMetadata: r,
  supportedLocales: a,
  currentLocale: l,
  hideReadOnly: o = !1,
  renderer: i
}) => {
  const s = _(() => {
    if (!r.attributes)
      return [];
    const d = o ? r.attributes.filter(({ readOnly: c }) => !c) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((c) => ({
      group: c,
      attributes: d.filter(
        (h) => h.group === c.name
      )
    }));
  }, [
    o,
    r.groups,
    r.attributes
  ]);
  return s.length === 0 ? null : /* @__PURE__ */ t(
    Xr,
    {
      label: e("jumpToSection"),
      sections: s.filter((d) => d.attributes.length > 0).map(({ group: d, attributes: c }) => ({
        title: te(e, d.displayHeader, d.name) || e("general"),
        panel: /* @__PURE__ */ v("div", { className: "pf-v5-c-form", children: [
          d.displayDescription && /* @__PURE__ */ t(Le, { className: "pf-v5-u-pb-lg", children: te(e, d.displayDescription, "") }),
          c.map((h) => /* @__PURE__ */ t(
            da,
            {
              t: e,
              form: n,
              supportedLocales: a,
              currentLocale: l,
              renderer: i,
              attribute: h
            },
            h.name
          ))
        ] })
      }))
    }
  );
}, da = ({
  t: e,
  form: n,
  renderer: r,
  supportedLocales: a,
  currentLocale: l,
  attribute: o
}) => {
  const i = n.watch(
    X(o.name)
  ), s = _(() => ha(o), [o]), d = o.multivalued || ma(i) && o.annotations?.inputType === void 0 ? Ue["multi-input"] : Ue[s];
  return o.name === "locale" ? /* @__PURE__ */ t(
    ta,
    {
      form: n,
      supportedLocales: a,
      currentLocale: l,
      t: e,
      attribute: o
    }
  ) : /* @__PURE__ */ t(
    d,
    {
      t: e,
      form: n,
      inputType: s,
      attribute: o,
      renderer: r
    }
  );
}, ua = "text";
function ha(e) {
  if ($t(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return pa(n) ? n : ua;
}
const pa = (e) => typeof e == "string" && e in Ue, ma = (e) => Array.isArray(e) && e.length > 1, fa = ({
  className: e = "",
  border: n,
  size: r = "md"
}) => /* @__PURE__ */ v(
  "svg",
  {
    className: fr(
      me.avatar,
      me.modifiers[r],
      n === "light" && me.modifiers.light,
      n === "dark" && me.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ t(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ t(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ t(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ t(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ t("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ v("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), yt = ({
  isKebab: e = !1,
  title: n,
  dropDownItems: r,
  ...a
}) => {
  const [l, o] = x(!1);
  return /* @__PURE__ */ t(
    In,
    {
      ...a,
      popperProps: {
        position: "right"
      },
      onOpenChange: (i) => o(i),
      toggle: (i) => /* @__PURE__ */ t(
        de,
        {
          "data-testid": `${a["data-testid"]}-toggle`,
          ref: i,
          onClick: () => o(!l),
          isExpanded: l,
          variant: e ? "plain" : "default",
          children: e ? /* @__PURE__ */ t(ir, {}) : n
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(wn, { children: r })
    }
  );
};
function ga(e, n) {
  if (!e)
    return n("unknownUser");
  const r = e.given_name, a = e.family_name, l = e.preferred_username;
  return r && a ? n("fullName", { givenName: r, familyName: a }) : r || a || l || n("unknownUser");
}
const tl = ({
  keycloak: e,
  brand: { src: n, alt: r, className: a, ...l },
  avatar: o,
  features: {
    hasLogout: i = !0,
    hasManageAccount: s = !0,
    hasUsername: d = !0
  } = {},
  kebabDropdownItems: c,
  dropdownItems: h = [],
  toolbarItems: u,
  ...f
}) => {
  const { t: m } = B(), b = [];
  s && b.push(
    /* @__PURE__ */ t(
      st,
      {
        onClick: () => e.accountManagement(),
        children: m("manageAccount")
      },
      "manageAccount"
    )
  ), i && b.push(
    /* @__PURE__ */ t(st, { onClick: () => e.logout(), children: m("signOut") }, "signOut")
  );
  const T = e.idTokenParsed?.picture;
  return /* @__PURE__ */ v(xn, { ...f, children: [
    /* @__PURE__ */ t(Tn, { children: /* @__PURE__ */ t(Sn, { variant: "plain", "aria-label": m("navigation"), children: /* @__PURE__ */ t(sr, {}) }) }),
    /* @__PURE__ */ t(An, { ...l, children: /* @__PURE__ */ t("img", { src: n, alt: r, className: a }) }),
    /* @__PURE__ */ t(En, { children: /* @__PURE__ */ t(ye, { children: /* @__PURE__ */ v(Ve, { children: [
      u?.map((g, p) => /* @__PURE__ */ t(K, { align: { default: "alignRight" }, children: g }, p)),
      /* @__PURE__ */ t(
        K,
        {
          visibility: {
            default: "hidden",
            md: "visible"
          },
          children: /* @__PURE__ */ t(
            yt,
            {
              "data-testid": "options",
              dropDownItems: [...h, b],
              title: d ? ga(e.idTokenParsed, m) : void 0
            }
          )
        }
      ),
      /* @__PURE__ */ t(
        K,
        {
          align: { default: "alignLeft" },
          visibility: {
            md: "hidden"
          },
          children: /* @__PURE__ */ t(
            yt,
            {
              "data-testid": "options-kebab",
              isKebab: !0,
              dropDownItems: [
                ...c || h,
                b
              ]
            }
          )
        }
      ),
      /* @__PURE__ */ t(
        K,
        {
          variant: "overflow-menu",
          align: { default: "alignRight" },
          className: "pf-v5-u-m-0-on-lg",
          children: T || o?.src ? /* @__PURE__ */ t(Rn, { src: T, alt: m("avatar"), ...o }) : /* @__PURE__ */ t(fa, { ...o })
        }
      )
    ] }) }) })
  ] });
}, Kt = Xe("ErrorBoundaryContext", void 0), Ut = () => Ze(Kt);
class nl extends $n {
  state = {};
  static getDerivedStateFromError = (n) => ({ error: n });
  showBoundary = (n) => {
    this.setState({ error: n });
  };
  render() {
    return /* @__PURE__ */ t(
      Kt.Provider,
      {
        value: { error: this.state.error, showBoundary: this.showBoundary },
        children: this.props.children
      }
    );
  }
}
const rl = ({ children: e, fallback: n }) => {
  const { error: r } = Ut();
  return r ? /* @__PURE__ */ t(n, { error: r }) : e;
};
function ya(e, n, r) {
  const { showBoundary: a } = Ut();
  le(() => {
    const l = new AbortController(), { signal: o } = l;
    return a(), e().then((i) => {
      o.aborted || n(i);
    }).catch((i) => {
      console.error(i), o.aborted || a(i);
    }), () => l.abort();
  }, r);
}
const ba = ({
  message: e,
  instructions: n,
  onPrimaryAction: r,
  hasIcon: a = !0,
  isSearchVariant: l,
  primaryActionText: o,
  secondaryActions: i,
  icon: s,
  isDisabled: d = !1
}) => /* @__PURE__ */ v(Dn, { "data-testid": "empty-state", variant: "lg", children: [
  a && l ? /* @__PURE__ */ t(ct, { icon: cr }) : a && /* @__PURE__ */ t(ct, { icon: s || Pt }),
  /* @__PURE__ */ t(On, { titleText: e, headingLevel: "h1" }),
  /* @__PURE__ */ t(Fn, { children: n }),
  /* @__PURE__ */ v(Pn, { children: [
    o && /* @__PURE__ */ t(
      L,
      {
        "data-testid": `${e.replace(/\W+/g, "-").toLowerCase()}-empty-action`,
        variant: "primary",
        onClick: r,
        isDisabled: d,
        children: o
      }
    ),
    i && /* @__PURE__ */ t(Mn, { children: i.map((c) => /* @__PURE__ */ t(
      L,
      {
        "data-testid": `${c.text.replace(/\W+/g, "-").toLowerCase()}-empty-action`,
        variant: c.type || be.secondary,
        onClick: c.onClick,
        isDisabled: d,
        children: c.text
      },
      c.text
    )) })
  ] })
] }), va = ({
  toolbarItem: e,
  subToolbar: n,
  toolbarItemFooter: r,
  children: a,
  searchTypeComponent: l,
  inputGroupName: o,
  inputGroupPlaceholder: i,
  inputGroupOnEnter: s
}) => {
  const { t: d } = B(), [c, h] = x(""), u = () => {
    c !== "" ? (h(c), s?.(c)) : (h(""), s?.(""));
  }, f = (m) => {
    m.key === "Enter" && u();
  };
  return /* @__PURE__ */ v(J, { children: [
    /* @__PURE__ */ t(ye, { children: /* @__PURE__ */ v(Ve, { children: [
      o && /* @__PURE__ */ t(K, { children: /* @__PURE__ */ v(Ce, { "data-testid": o, children: [
        l,
        i && /* @__PURE__ */ t(
          Ln,
          {
            "data-testid": "table-search-input",
            placeholder: i,
            "aria-label": d("search"),
            value: c,
            onChange: (m, b) => {
              h(b);
            },
            onSearch: u,
            onKeyDown: f,
            onClear: () => {
              h(""), s?.("");
            }
          }
        )
      ] }) }),
      e
    ] }) }),
    n && /* @__PURE__ */ t(ye, { children: /* @__PURE__ */ t(Ve, { children: n }) }),
    /* @__PURE__ */ t(Nn, {}),
    a,
    /* @__PURE__ */ t(ye, { children: r })
  ] });
}, bt = ({
  id: e,
  variant: n = "top",
  count: r,
  first: a,
  max: l,
  onNextClick: o,
  onPreviousClick: i,
  onPerPageSelect: s
}) => {
  const { t: d } = B(), c = Math.round(a / l);
  return /* @__PURE__ */ t(
    Bn,
    {
      widgetId: e,
      titles: {
        paginationAriaLabel: `${d("pagination")} ${n} `
      },
      isCompact: !0,
      toggleTemplate: ({
        firstIndex: h,
        lastIndex: u
      }) => /* @__PURE__ */ v("b", { children: [
        h,
        " - ",
        u
      ] }),
      itemCount: r + c * l,
      page: c + 1,
      perPage: l,
      onNextClick: (h, u) => o((u - 1) * l),
      onPreviousClick: (h, u) => i((u - 1) * l),
      onPerPageSelect: (h, u, f) => s(f - 1, u),
      variant: n
    }
  );
}, Ca = ({
  count: e,
  searchTypeComponent: n,
  toolbarItem: r,
  subToolbar: a,
  children: l,
  inputGroupName: o,
  inputGroupPlaceholder: i,
  inputGroupOnEnter: s,
  ...d
}) => /* @__PURE__ */ t(
  va,
  {
    searchTypeComponent: n,
    toolbarItem: /* @__PURE__ */ v(J, { children: [
      r,
      /* @__PURE__ */ t(K, { variant: "pagination", children: /* @__PURE__ */ t(bt, { count: e, ...d }) })
    ] }),
    subToolbar: a,
    toolbarItemFooter: e !== 0 ? /* @__PURE__ */ t(K, { variant: "pagination", children: /* @__PURE__ */ t(bt, { count: e, variant: "bottom", ...d }) }) : null,
    inputGroupName: o,
    inputGroupPlaceholder: i,
    inputGroupOnEnter: s,
    children: l
  }
), ka = () => {
  const { t: e } = B();
  return /* @__PURE__ */ t(Vn, { children: /* @__PURE__ */ t(Ct, { "aria-label": e("spinnerLoading") }) });
}, Pe = ({ row: e }) => {
  const n = (r) => !!r && r.title !== void 0;
  return e.cells.map((r, a) => /* @__PURE__ */ t(re, { children: n(r) ? r.title : r }, `cell-${a}`));
};
function Ia({
  columns: e,
  rows: n,
  actions: r,
  actionResolver: a,
  ariaLabelKey: l,
  onSelect: o,
  onCollapse: i,
  canSelectAll: s,
  isNotCompact: d,
  isRadio: c,
  ...h
}) {
  const { t: u } = B(), [f, m] = x([]), [b, T] = x([]), g = (p, C) => {
    const w = [
      ...p === -1 ? Array(n.length).fill(C) : f
    ];
    w[p] = C, m(w);
  };
  return le(() => {
    if (s) {
      const p = document.getElementsByName("check-all").item(0);
      if (p) {
        const C = p, w = f.filter((k) => k === !0);
        C.indeterminate = w.length < n.length && w.length > 0;
      }
    }
  }, [f]), /* @__PURE__ */ v(
    gr,
    {
      ...h,
      variant: d ? void 0 : yr.compact,
      "aria-label": u(l),
      children: [
        /* @__PURE__ */ t(br, { children: /* @__PURE__ */ v(fe, { children: [
          i && /* @__PURE__ */ t(Oe, {}),
          s && /* @__PURE__ */ t(
            Oe,
            {
              select: c ? void 0 : {
                onSelect: (p, C, w) => {
                  o(C, w), g(-1, C);
                },
                isSelected: f.filter((p) => p === !0).length === n.length
              }
            }
          ),
          e.map((p) => /* @__PURE__ */ t(
            Oe,
            {
              className: p.transforms?.[0]().className,
              children: u(p.displayKey || p.name)
            },
            p.displayKey
          ))
        ] }) }),
        i ? n.map((p, C) => /* @__PURE__ */ t(ut, { children: C % 2 === 0 ? /* @__PURE__ */ v(fe, { children: [
          /* @__PURE__ */ t(
            re,
            {
              expand: {
                isExpanded: !!b[C],
                rowIndex: C,
                expandId: `${C}`,
                onToggle: (w, k, A) => {
                  i(A, k);
                  const V = [...b];
                  V[C] = A, T(V);
                }
              }
            }
          ),
          /* @__PURE__ */ t(Pe, { row: p })
        ] }) : /* @__PURE__ */ v(fe, { isExpanded: !!b[C - 1], children: [
          /* @__PURE__ */ t(re, {}),
          /* @__PURE__ */ t(re, { colSpan: e.length, children: /* @__PURE__ */ t(Cr, { children: /* @__PURE__ */ t(Pe, { row: p }) }) })
        ] }) }, C)) : /* @__PURE__ */ t(ut, { children: n.map((p, C) => /* @__PURE__ */ v(fe, { isExpanded: b[C], children: [
          o && /* @__PURE__ */ t(
            re,
            {
              select: {
                rowIndex: C,
                onSelect: (w, k, A) => {
                  o(k, A), g(A, k);
                },
                isSelected: f[C],
                variant: c ? "radio" : "checkbox"
              }
            }
          ),
          /* @__PURE__ */ t(Pe, { row: p }),
          (r || a) && /* @__PURE__ */ t(re, { isActionCell: !0, children: /* @__PURE__ */ t(
            vr,
            {
              items: r || a?.(p, {}),
              extraData: { rowIndex: C }
            }
          ) })
        ] }, C)) })
      ]
    }
  );
}
function wa({
  ariaLabelKey: e,
  searchPlaceholderKey: n,
  isPaginated: r = !1,
  onSelect: a,
  canSelectAll: l = !1,
  isNotCompact: o,
  isRadio: i,
  detailColumns: s,
  isRowDisabled: d,
  loader: c,
  columns: h,
  actions: u,
  actionResolver: f,
  searchTypeComponent: m,
  toolbarItem: b,
  subToolbar: T,
  emptyState: g,
  icon: p,
  isSearching: C = !1,
  ...w
}) {
  const { t: k } = B(), [A, V] = x([]), [y, S] = x(), [D, F] = x(), [E, P] = x(!1), [N, Se] = Lt(
    localStorage,
    "pageSize",
    10
  ), [H, Ht] = x(N), [$, ie] = x(0), [W, Ae] = x(""), nt = Q(), [he, Wt] = x(0), rt = Q(), at = () => Wt(he + 1), jt = Et(), lt = (I, O) => I.map((R) => {
    if ("cellFormatters" in R) {
      const j = U(O, R.name);
      return R.cellFormatters?.reduce((G, M) => M(G), j);
    }
    if (R.cellRenderer) {
      const j = R.cellRenderer;
      return { title: /* @__PURE__ */ t(j, { ...O }) };
    }
    return U(O, R.name);
  }), ot = (I) => {
    const O = (R) => s?.[0]?.enabled?.(R);
    return I.map((R, j) => {
      const G = d ? d(R) : !1, M = [
        {
          data: R,
          disableSelection: G,
          disableActions: G,
          selected: !!A.find((Xt) => U(Xt, "id") === U(R, "id")),
          isOpen: O(R) ? !1 : void 0,
          cells: lt(h, R)
        }
      ];
      return s && M.push({
        parent: j * 2,
        cells: O(R) ? lt(s, R) : []
      }), M;
    }).flat();
  }, Ee = (I) => ["string", "number"].includes(typeof I) ? I.toString() : I instanceof Array ? I.map(Ee).join("") : typeof I == "object" ? Ee(
    Kn(I.title) ? I.title.props : Object.values(I)
  ) : "", Re = _(
    () => W === "" || r ? void 0 : ot(D || []).filter(
      (I) => I.cells.some(
        (O) => O && Ee(O).toLowerCase().includes(W.toLowerCase())
      )
    ).slice($, $ + H + 1),
    [W, $, H]
  );
  ya(
    async () => {
      P(!0);
      const I = nt.current === "" && W !== "";
      return I && ie(0), nt.current = W, typeof c == "function" ? he === rt.current && D ? D : await c(I ? 0 : $, H + 1, W) : c;
    },
    (I) => {
      rt.current = he, r || (F(I), I.length > $ ? I = I.slice($, $ + H + 1) : ie(0));
      const O = ot(I);
      S(O), P(!1);
    },
    [
      he,
      $,
      H,
      W,
      typeof c != "function" ? c : void 0
    ]
  );
  const Gt = () => u && pr(u).map((I, O) => (delete I.onRowClick, I.onClick = async (R, j) => {
    await u[O].onRowClick(
      (Re || y)[j].data
    ) && (r || Ae(""), at());
  }, I)), zt = (I, O) => {
    const R = Re || y;
    O === -1 ? S(
      R.map((M) => (M.selected = I, M))
    ) : (R[O].selected = I, S([...y]));
    const G = [
      ...mr(
        A,
        R.map((M) => M.data),
        "id"
      ),
      ...R.filter((M) => M.selected).map((M) => M.data)
    ];
    V(G), a(G);
  }, Jt = (I, O) => {
    Z[O].isOpen = I, S([...Z]);
  }, Z = Re || y, pe = !Z || Z.length === 0, De = W !== "" || C, Yt = s ? H * 2 : H, Qt = s ? (Z?.length || 0) / 2 : Z?.length || 0;
  return /* @__PURE__ */ v(J, { children: [
    (E || !pe || De) && /* @__PURE__ */ v(
      Ca,
      {
        id: jt,
        count: Qt,
        first: $,
        max: H,
        onNextClick: ie,
        onPreviousClick: ie,
        onPerPageSelect: (I, O) => {
          ie(I), Ht(O), Se(O);
        },
        inputGroupName: n ? `${e}input` : void 0,
        inputGroupOnEnter: Ae,
        inputGroupPlaceholder: k(n || ""),
        searchTypeComponent: m,
        toolbarItem: /* @__PURE__ */ v(J, { children: [
          b,
          " ",
          /* @__PURE__ */ t(K, { variant: "separator" }),
          " ",
          /* @__PURE__ */ t(K, { children: /* @__PURE__ */ v(L, { variant: "link", onClick: at, children: [
            /* @__PURE__ */ t(dr, {}),
            " ",
            k("refresh")
          ] }) })
        ] }),
        subToolbar: T,
        children: [
          !E && !pe && /* @__PURE__ */ t(
            Ia,
            {
              ...w,
              canSelectAll: l,
              onSelect: a ? zt : void 0,
              onCollapse: s ? Jt : void 0,
              actions: Gt(),
              actionResolver: f,
              rows: Z.slice(0, Yt),
              columns: h,
              isNotCompact: o,
              isRadio: i,
              ariaLabelKey: e
            }
          ),
          !E && pe && De && /* @__PURE__ */ t(
            ba,
            {
              hasIcon: !0,
              icon: p,
              isSearchVariant: !0,
              message: k("noSearchResults"),
              instructions: k("noSearchResultsInstructions"),
              secondaryActions: C ? [] : [
                {
                  text: k("clearAllFilters"),
                  onClick: () => Ae(""),
                  type: be.link
                }
              ]
            }
          ),
          E && /* @__PURE__ */ t(ka, {})
        ]
      }
    ),
    !E && pe && !De && g
  ] });
}
const xa = ({ link: e, organization: n }) => {
  const { t: r } = B();
  return /* @__PURE__ */ t(kr, { wrapModifier: "truncate", children: /* @__PURE__ */ v(e, { organization: n, children: [
    n.name,
    !n.enabled && /* @__PURE__ */ t(
      _n,
      {
        isRead: !0,
        className: "pf-v5-u-ml-sm",
        children: r("disabled")
      },
      `${n.id}-disabled`
    )
  ] }) });
}, Ta = (e) => {
  const { t: n } = B();
  return /* @__PURE__ */ t(
    Je,
    {
      numChips: 2,
      expandedText: n("hide"),
      collapsedText: n("showRemaining"),
      children: e.domains?.map((r) => {
        const a = typeof r == "string" ? r : r.name;
        return /* @__PURE__ */ t(Ye, { isReadOnly: !0, children: a }, a);
      })
    }
  );
}, al = ({
  loader: e,
  toolbarItem: n,
  isPaginated: r = !1,
  onSelect: a,
  onDelete: l,
  deleteLabel: o = "delete",
  link: i,
  children: s
}) => {
  const { t: d } = B();
  return /* @__PURE__ */ t(
    wa,
    {
      loader: e,
      isPaginated: r,
      ariaLabelKey: "organizationList",
      searchPlaceholderKey: "searchOrganization",
      toolbarItem: n,
      onSelect: a,
      canSelectAll: a !== void 0,
      actions: l ? [
        {
          title: d(o),
          onRowClick: l
        }
      ] : void 0,
      columns: [
        {
          name: "name",
          displayKey: "name",
          cellRenderer: (c) => /* @__PURE__ */ t(xa, { link: i, organization: c })
        },
        {
          name: "domains",
          displayKey: "domains",
          cellRenderer: Ta
        },
        {
          name: "description",
          displayKey: "description"
        }
      ],
      emptyState: s
    }
  );
};
export {
  Fr as AlertProvider,
  Ka as ContinueCancelModal,
  rl as ErrorBoundaryFallback,
  nl as ErrorBoundaryProvider,
  Pr as ErrorPage,
  Bt as FormErrorText,
  Gr as FormPanel,
  Ja as FormSubmitButton,
  Br as Help,
  Vt as HelpItem,
  za as IconMapper,
  wa as KeycloakDataTable,
  tl as KeycloakMasthead,
  qa as KeycloakProvider,
  sa as KeycloakSelect,
  ka as KeycloakSpinner,
  Ur as KeycloakTextArea,
  ba as ListEmptyState,
  Ua as NumberControl,
  al as OrganizationTable,
  Ca as PaginatingTableToolbar,
  Ha as PasswordControl,
  _t as PasswordInput,
  Xr as ScrollForm,
  Kr as SelectControl,
  ae as SelectVariant,
  Wa as SwitchControl,
  va as TableToolbar,
  ja as TextAreaControl,
  Ga as TextControl,
  el as UserProfileFields,
  Ya as beerify,
  Xe as createNamedContext,
  Qa as debeerify,
  Ar as generateId,
  xr as getErrorDescription,
  wr as getErrorMessage,
  $a as getInjectedEnvironment,
  Tr as getNetworkErrorDescription,
  Sr as getNetworkErrorMessage,
  Er as isDefined,
  Za as isUserProfileError,
  te as label,
  Qr as mainPageContentId,
  Xa as setUserProfileServerError,
  Va as useAlerts,
  _a as useEnvironment,
  Ut as useErrorBoundary,
  ya as useFetch,
  Nr as useHelp,
  Ze as useRequiredContext,
  Rr as useSetTimeout,
  Lt as useStoredState
};
